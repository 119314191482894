import * as React from "react"
import styled from 'styled-components/macro'
import DolomiteDevicesHeaderImage from '../../images/borrow-page.png'
import DolomiteDevicesHeaderImageBlurred from '../../images/borrow-page-blurred.png'
import {keyframes} from "styled-components";
import {useEffect, useState} from "react";
import { Link } from "gatsby"

const TitleSectionWrapper = styled.div`
  width: 100%;
  height: calc(100vh + 50px);
  overflow: hidden;
  background-color: #1e1c29;
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%2340444f' fill-opacity='0.35'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
  
  > div:nth-of-type(1) {
    width: calc(100% - 17px);
    height: calc(100vh + 100px);
  }
  
  @media screen and (max-width: 880px) {
    height: 885px;
    
    > div:nth-of-type(1) {
      width: calc(100% - 40px);
      max-width: 480px;
    }
  }
`

const TitleSectionInner = styled.div`
  max-width: 1480px;
  height: 100%;
  padding-right: 80px;
  width: calc(100% - 80px);
  margin: 0 auto;
  
  @media screen and (max-width: 1100px) {
    width: calc(100% - 60px);
    padding-right: 60px;
  }
  
  @media screen and (max-width: 880px) {
    padding: 0 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`

const RightSide = styled.div`
  display: inline-block;
  width: 38.2%;
  height: 100%;
  vertical-align: top;
  color: #f9f9f9;
  
  @media screen and (max-width: 1100px) {
    margin-right: 2%;
  }
  
  /*@media screen and (max-width: 880px) {
    width: 100%;
    text-align: center;
    margin-top: 5vh;
    height: fit-content;
  }*/
  
  @media screen and (max-width: 880px) {
    width: 100%;
    height: fit-content;
    position: relative;
    margin-top: 50vh;
    text-align: center;
  }
`

const RightSideInner = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  align-items: center;
  justify-content: left;
  position: relative;
  
  @media screen and (max-width: 880px) {
    position: absolute;
    z-index: 3;
  }
`

const RightSideContent = styled.div`
  display: inline-block;
  height: fit-content;
  
  @media screen and (max-width: 880px) {
    max-width: 420px;
    margin: 0 auto;
  }
`

const TitleText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.1em;
  width: 100%;
  margin-bottom: 10px;
  
  @media screen and (max-width: 1100px) {
    font-size: 2.5em;
    line-height: 1em;
  }
  
  @media screen and (max-width: 880px) {
    font-size: 2em;
  }
`

const SubtitleText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 1em;
  font-weight: 300;
  color: #d5d6e1;
  margin-bottom: 20px;
  letter-spacing: 0.3px;
  
  @media screen and (max-width: 880px) {
    font-size: 0.9em;
  }
`

const ActionButtons = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: left;

  a {
    text-decoration: none;
    color: inherit;
  }
    
  @media screen and (max-width: 880px) {
    text-align: center;
  }
`

const LaunchAppButton = styled.div`
  width: fit-content;
  padding: 12px 24px;
  background-color: #8fc942;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
  
  :hover {
    background-color: #a1db54;
  }
  
  @media screen and (max-width: 1100px) {
    width: calc(100% - 48px);
    margin-bottom: 15px;
    margin-right: 0;
    text-align: center;
  }
  
  @media screen and (max-width: 880px) {
    margin-right: 0;
    min-width: 50%;
    font-size: 16px;
    padding: 10px 20px;
  }
`

const QuickStartGuideButton = styled(LaunchAppButton)`
  background-color: #565A69;
  display: inline-block;
  
  :hover {
    background: #6C7284;
  }
  
  @media screen and (max-width: 1100px) {
    width: calc(100% - 48px);
    text-align: center;
  }
  
  @media screen and (max-width: 880px) {
    margin-top: 12px;
  }
`

const GuideLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`

const LeftSide = styled.div`
  display: inline-block;
  width: 61.8%;
  height: 100%;
  vertical-align: top;
  
  @media screen and (max-width: 1100px) {
    width: 59.8%;
  }
  
  /*@media screen and (max-width: 880px) {
    height: 40vh;
    margin-top: 10vh;
  }*/
  
  @media screen and (max-width: 880px) {
    /*height: calc(120vh + 30px);*/
    height: 915px;
    position: absolute;
    top: 880px;
    width: 100%;
    z-index: 2;
    right: 0;
  }
`

const DriftAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  
  to {
    transform: translateX(calc(100% - 100vw));
  }
`

const LeftSideImageWrapper = styled.div`
  display: flex;
  justify-content: right;
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
  height: 82%;
  
  img {
    width: auto;
    height: 100%;
    border-radius: 8px;
    box-shadow: 3px 7px 8px -2px rgb(0 0 0 / 50%), 4px 8px 21px 4px rgb(0 0 0 / 50%);
  }
  
  > div > img:nth-of-type(2) {
    display: none;
  }
  
  /*@media screen and (max-width: 880px) {
    margin-top: 0;
    margin-left: 50px;
  }*/
  
  @media screen and (max-width: 880px) {
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
    
    > div {
      width: fit-content;
      height: 100%;
      position: absolute;
      
      > img:nth-of-type(1) {
        display: none;
      }
      
      > img:nth-of-type(2) {
        display: block;
      }
    }
      
    img {
      height: 100%;
      width: auto;
      animation: ${DriftAnimation} 50s ease-out infinite alternate;
    }
  }
`

// markup
const TitleSection = () => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(window.innerWidth)

    window.addEventListener('resize', () => setWidth(window.innerWidth))
    return () => window.removeEventListener('resize', () => setWidth(window.innerWidth))
  }, [])


  return (
    <TitleSectionWrapper>
      <TitleSectionInner>
        <LeftSide>
          <LeftSideImageWrapper>
            <div>
              <img src={DolomiteDevicesHeaderImage} alt={'Dolomite Exchange'} />
              <img src={DolomiteDevicesHeaderImageBlurred} alt={'Dolomite Exchange'} />
            </div>
          </LeftSideImageWrapper>
        </LeftSide>
        <RightSide>
          <RightSideInner>
            <RightSideContent>
              <TitleText>
                Groundbreaking<br/>lending & borrowing
              </TitleText>
              <SubtitleText>
                Unlock dormant capital by borrowing and lending in new ways and with new assets through powerful integrations across the DeFi ecosystem.
              </SubtitleText>
              <ActionButtons>
                <a href={'https://app.dolomite.io/Borrow'}>
                  <LaunchAppButton>
                    Launch App
                  </LaunchAppButton>
                </a>
                <a href={'https://docs.dolomite.io/integrations'}>
                  <QuickStartGuideButton>
                    Integrations
                  </QuickStartGuideButton>
                </a>
              </ActionButtons>
            </RightSideContent>
          </RightSideInner>
        </RightSide>
      </TitleSectionInner>
    </TitleSectionWrapper>
  )
}

export default TitleSection
